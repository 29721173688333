import request from '@/utils/request'

const URL = {
  getSolution: '/api/solution/GetSln',
  addSolution: '/api/solution/add',
}

/**
 * 获取解决方案列表
 * @param {Object} data
 * @returns
 */
export function getSln (data) {
  return request({
    url: URL.getSolution,
    method: 'post',
    data,
  })
}

export function getMySln (data) {
  return request({
    url: '/api/solution/GetMySln',
    method: 'post',
    data,
  })
}

/**
 * 根据id获取详细的解决方案详情
 * @param {Number} id
 * @returns
 */
export function getInfoById (id) {
  return request({
    url: '/api/solution/GetInfoById',
    method: 'get',
    params: {
      id,
    },
  })
}

// GetMyInfoById
export function getMyInfoById (id) {
  return request({
    url: '/api/solution/GetMyInfoById',
    method: 'get',
    params: {
      id,
    },
  })
}

/**
 *
 * @param {Object} data
 * @returns
 */
export function addSln (data) {
  return request({
    url: URL.addSolution,
    method: 'post',
    data,
  })
}

export function updataSln (orderId, data) {
  return request({
    url: '/api/solution/Update',
    method: 'post',
    params: { orderId },
    data,
  })
}

/**
 * 下载当前订单中的中间解决方案啊
 * @param {Number} id
 * @returns
 */
export function downLoadSln (id) {
  return request({
    url: '/api/solution/DownLoadSln',
    method: 'get',
    params: {
      id,
    },
    responseType: 'blob',
  })
}

export function downLoadSln2 (id) {
  return request({
    url: '/api/solution/DownLoadSln2',
    method: 'get',
    params: {
      id,
    },
  })
}

/**
 * 删除当前解决方案
 * @param {Number} id
 * @returns
 */
export function deleteSln (id) {
  return request({
    url: '/api/solution/Delete',
    method: 'get',
    params: {
      id,
    },
  })
}

/**
 * 对当前解决方案进行复制操作
 * @param {*} id 
 * @returns 
 */
export function copySln(id){
  return request({
    url: '/api/solution/Copy',
    method: 'get',
    params: {
      id,
    },
  })
}

/**
 * 对当前解决方案进行复制操作
 * @param {*} id 
 * @returns 
 */
export function ShareSln(userId,userName,orderId){
  return request({
    url: '/api/solution/Share',
    method: 'get',
    params: {
      userId,
      userName,
      orderId
    },
  })
}
