<template>
  <el-card>
    <div slot="header">
      <span v-show="!inputVisible">系统名称:{{ title }}</span>
      <el-input
        class="button-new-tag"
        v-if="inputVisible"
        v-model="copyTitle"
        :ref="'saveSlnNameRef' + id"
        size="small"
        @keyup.enter.native="handleInputConfirm"
        @blur="handleInputConfirm"
      >
      </el-input>
      <el-button
        v-else
        type="text"
        icon="el-icon-edit"
        @click="showInput"
      ></el-button>

      <el-button
        size="mini"
        style="float: right"
        type="primary"
        @click="addProductDialogVisabled = true"
        >新增商品</el-button
      >
      <el-button
        size="mini"
        style="float: right"
        type="danger"
        @click="deleteHandler"
        >删除</el-button
      >
    </div>

    <el-table
      :ref="'slnTable' + id"
      :summary-method="getSummaries"
      :show-summary="true"
      :data="productList"
      style="width: 100%; margin-bottom: 20px"
      height="300px"
    >
      <el-table-column type="index" label="#" width="60"> </el-table-column>
      <el-table-column prop="productName" label="名称"> </el-table-column>
      <el-table-column prop="amount" label="数量"> </el-table-column>
      <el-table-column prop="price" label="价格"> </el-table-column>
      <el-table-column label="总价格">
        <template slot-scope="scopes">
          {{ scopes.row.amount * scopes.row.price }}
        </template>
      </el-table-column>
      <el-table-column label="型号规格">
        <template slot-scope="scopes">
          {{ scopes.row.productModel + '/' + scopes.row.productSpecs }}
        </template>
      </el-table-column>
      <el-table-column label="编辑" width="150">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="openEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-button type="danger" size="mini" @click="deleteProduct(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-row>
      <el-form>
        <el-row :gutter="10">
          <el-col :span="12"
            ><span>
              <span v-show="!subInputVisible">子系统:{{ subTitle }}</span>
              <el-input
                class="button-new-tag_1"
                v-if="subInputVisible"
                v-model="subCopyTitle"
                :ref="'subsystemName' + id"
                size="small"
                @keyup.enter.native="subHandleInputConfirm"
                @blur="subHandleInputConfirm"
              >
              </el-input>
              <el-button
                v-else
                type="text"
                icon="el-icon-edit"
                @click="subShowInput"
              ></el-button> </span
          ></el-col>
          <el-col :span="12">
            <span style="float: right">
              <span v-show="!areaInputVisible">区域:{{ areaName }}</span>
              <el-input
                class="button-new-tag_1"
                v-if="areaInputVisible"
                v-model="areaCopyTitle"
                :ref="'areaName' + id"
                size="small"
                @keyup.enter.native="areaHandleInputConfirm"
                @blur="areaHandleInputConfirm"
              >
              </el-input>
              <el-button
                v-else
                type="text"
                icon="el-icon-edit"
                @click="areaShowInput"
              ></el-button>
            </span>
          </el-col>
        </el-row>
        <el-form-item label="系统备注">
          <el-input
            type="textarea"
            v-model="cRemark"
            @keyup.enter.native="remarkHandler"
            @blur="remarkHandler"
          ></el-input>
        </el-form-item>
      </el-form>
    </el-row>

    <!-- 新增商品对话框 -->
    <el-dialog
      title="新增商品"
      :visible.sync="addProductDialogVisabled"
      @close="addFormDialogClose"
    >
      <el-form
        ref="addProductFormRef"
        :model="addProductForm"
        :rules="addProductFormRules"
        label-width="100"
      >
        <el-form-item prop="productName" label="商品名称">
          <el-select
            v-model="addProductForm.productId"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键词"
            @focus="onFocus"
            :remote-method="querySearch"
            @change="productSelect"
          >
            <el-option
              v-for="item in productOptions"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item> 

        <el-form-item label="商品型号">
          <el-select
            v-model="addProductForm.modelName"
            placeholder="请输入关键词"
          >
            <el-option
              v-for="item in modelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="商品规格">
          <el-select
            v-model="addProductForm.productSpecs"
            placeholder="请输入关键词"
            @change="specsOptionsChange"
          >
            <el-option
              v-for="item in specsOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="count" label="商品数量">
          <el-input
            v-model="addProductForm.count"
            autocomplete="off"
            placeholder="商品数量"
          ></el-input>
        </el-form-item>
        <el-form-item prop="price" label="商品价格">
          <el-input
            v-model="addProductForm.price"
            autocomplete="off"
            placeholder="商品价格"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品备注">
          <el-input
            v-model="addProductForm.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addProductDialogVisabled = false">取 消</el-button>
        <el-button type="primary" @click="addFormDialogConfim">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 编辑商品数量对话框 -->
    <el-dialog title="编辑商品" :visible.sync="editProductDialogVisabled">
      <el-form
        ref="editProductFormRef"
        :model="editProductForm"
        :rules="editProductFormRules"
        label-width="100"
      >
        <el-form-item prop="productName" label="商品名称">
          <el-input
            disabled
            v-model="editProductForm.productName"
            autocomplete="off"
            placeholder="商品名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="商品型号">
          <el-input
            disabled
            v-model="editProductForm.productModel"
            autocomplete="off"
            placeholder="商品型号"
          ></el-input>
        </el-form-item>

        <el-form-item label="商品规格">
          <el-input
            disabled
            v-model="editProductForm.productSpecs"
            autocomplete="off"
            placeholder="商品规格"
          ></el-input>
        </el-form-item>

        <el-form-item prop="amount" label="商品数量">
          <el-input
            v-model="editProductForm.amount"
            autocomplete="off"
            placeholder="商品数量"
          ></el-input>
        </el-form-item>
        <el-form-item prop="price" label="商品价格">
          <el-input
            v-model="editProductForm.price"
            autocomplete="off"
            placeholder="商品价格"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品备注">
          <el-input
            v-model="editProductForm.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editProductDialogVisabled = false">取 消</el-button>
        <el-button type="primary" @click="editFormDialogConfim"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import { getFilterProduct } from '@/api/product.js'
import { validateNumber } from '@/utils/validate.js'
import { iosOnFocus } from '@/utils/iosPlatform.js'
// import { nextTick } from 'vue/types/umd'
export default {
  props: {
    id: Number,
    title: String,
    subTitle: String,
    areaName: String,
    remark: String,
    productList: Array,
  },
  data() {
    let validateNumberValid = (rule, value, callback) => {
      if (validateNumber(value)) {
        var number = Number(value)

        if (number < 0) {
          callback(new Error('当前数据不能为负数'))
        } else {
          callback()
        }
      } else {
        callback(new Error('当前类型为非数字类型'))
      }
    }

    return {
      copyTitle: '',
      inputVisible: false,
      subCopyTitle: '',
      subInputVisible: false,
      areaCopyTitle: '',
      areaInputVisible: false,
      cRemark: this.remark,
      addProductDialogVisabled: false,
      modelOptions: [],
      specsOptions: [],
      addProductForm: {
        productId: null,
        productName: '',
        modelName: '',
        productSpecs: '',
        count: '1',
        price: '',
        remark: '',
      },
      addProductFormRules: {
        productName: [
          {
            required: true,
            message: '请输入商品名称',
            trigger: 'blur',
          },
        ],
        count: [
          {
            required: true,
            message: '请输入商品数量',
            trigger: 'blur',
          },
          {
            validator: validateNumberValid,
            trigger: 'blur',
          },
        ],
        price: [
          {
            required: true,
            message: '请输入商品价格',
            trigger: 'blur',
          },
          {
            validator: validateNumberValid,
            trigger: 'blur',
          },
        ],
      },

      editProductDialogVisabled: false,
      editProductForm: {},
      editProductFormRules: {
        productName: [
          {
            required: true,
            message: '请输入商品名称',
            trigger: 'blur',
          },
        ],
        amount: [
          {
            required: true,
            message: '请输入商品数量',
            trigger: 'blur',
          },
          {
            validator: validateNumberValid,
            trigger: 'blur',
          },
        ],
        price: [
          {
            required: true,
            message: '请输入商品价格',
            trigger: 'blur',
          },
          {
            validator: validateNumberValid,
            trigger: 'blur',
          },
        ],
      },
      productOptions: [],
    }
  },
  methods: {
    showInput() {
      this.inputVisible = true
      this.copyTitle = this.title
      this.$nextTick((_) => {
        this.$refs['saveSlnNameRef' + this.id].$refs.input.focus()
      })
    },
    handleInputConfirm() {
      let inputValue = this.copyTitle
      if (inputValue) {
        this.$emit('changeSystemName', this.id, inputValue)
      }
      this.inputVisible = false
      this.copyTitle = ''
    },
    subShowInput() {
      this.subInputVisible = true
      this.subCopyTitle = this.subTitle
      this.$nextTick((_) => {
        this.$refs['subsystemName' + this.id].$refs.input.focus()
      })
    },
    subHandleInputConfirm() {
      let subInputValue = this.subCopyTitle
      if (subInputValue) {
        this.$emit('changeSubsystemName', this.id, subInputValue)
      }
      this.subInputVisible = false
      this.subCopyTitle = ''
    },
    areaShowInput() {
      this.areaInputVisible = true
      this.areaCopyTitle = this.areaName
      this.$nextTick((_) => {
        this.$refs['areaName' + this.id].$refs.input.focus()
      })
    },
    areaHandleInputConfirm() {
      let areaInputValue = this.areaCopyTitle
      if (areaInputValue) {
        this.$emit('changeAreaName', this.id, areaInputValue)
      }
      this.areaInputVisible = false
      this.areaCopyTitle = ''
    },
    remarkHandler() {
      this.$emit('changeRemark', this.id, this.cRemark)
    },
    deleteHandler() {
      this.$confirm('当前操作将会删除本预设系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$emit('deleteSystem', this.id)
        })
        .catch(() => {})
    },
    // 表格统计
    // 表格尾行合计行
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        // 第一列索引
        if (index === 0) {
          sums[index] = '总价'
          return
        }

        // 第二列商品名称
        if (index === 2) {
          const values = data.map((item) => Number(item[column.property]))
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] += ' 件'
            return
          } else {
            sums[index] = 'N/A'
            return
          }
        }

        // 第四列总价
        if (index === 4) {
          const priceArr = data.map((item) => {
            return Number(item['price'] * item['amount'])
          })

          if (!priceArr.every((value) => isNaN(value))) {
            sums[index] = priceArr
              .reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)
              .toFixed(2)
            sums[index] += ' 元'
            return
          } else {
            sums[index] = 'N/A'
            return
          }
        }

        sums[index] = ''
      })

      this.$nextTick((_) => {
        this.$refs['slnTable' + this.id].doLayout()
      })

      return sums
    },
    onFocus(event) {
      iosOnFocus.call(this,event);
    },
    // 获取商品的数据
    async querySearch(queryString) {
      if (queryString !== '') {
        let { data } = await getFilterProduct(queryString)
        this.productOptions = data
      } else {
        this.productOptions = []
      }
    },
    // 商品选择更改
    productSelect(id) {
      let item = this.productOptions.filter((item) => {
        return item.id == id
      })[0]
      //      console.log(item)
      this.addProductForm.productName = item.productName
      this.addProductForm.price = item.price

      if (item.modelOptions && item.modelOptions.length > 0) {
        this.modelOptions = item.modelOptions
        this.addProductForm.modelName = item.modelOptions[0].value
      }

      // if (item.specsOptions && item.specsOptions.length > 0) {
      //   this.specsOptions = item.specsOptions
      //   this.addProductForm.productSpecs = item.specsOptions[0].value
      // }

      if (item.attribute && item.attribute.length > 0) {
        this.specsOptions = item.attribute
        this.addProductForm.productSpecs = item.attribute[0].value
        this.addProductForm.price = item.attribute[0].price
      }
    },
    specsOptionsChange(id) {
      if (this.specsOptions.some((u) => u.value == id)) {
        let item = this.specsOptions.filter((i) => {
          return i.value == id
        })[0]

        this.addProductForm.productSpecs = item.value
        this.addProductForm.price = item.price
      }
    },
    addFormDialogConfim() {
      this.$refs.addProductFormRef.validate((valid) => {
        if (valid) {
          let filterEntity = this.productList.filter((item) => {
            return (
              item.productId == this.addProductForm.productId &&
              item.productModel == this.addProductForm.modelName &&
              item.productSpecs == this.addProductForm.productSpecs
            )
          })

          if (filterEntity.length > 0) {
            return this.$message.error('当前系统中已经存在该产品')
          } else {
            this.$emit('pushProductList', this.id, {
              productId: this.addProductForm.productId,
              productName: this.addProductForm.productName,
              productModel: this.addProductForm.modelName,
              productSpecs: this.addProductForm.productSpecs,
              amount: parseInt(this.addProductForm.count),
              price: Number(this.addProductForm.price).toFixed(2),
              remark: this.addProductForm.remark,
            })

            this.addProductDialogVisabled = false
            this.$message.success('添加商品成功')
          }
        } else {
          return this.$message.error('当前商品参数填写不正确.')
        }
      })
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    addFormDialogClose() {
      this.productOptions = []
      this.addProductForm.productId = null
      this.resetForm('addProductFormRef')
    },
    // 删除列表中商品的个数
    deleteProduct(row) {
      this.$confirm('当前操作将会删除当前系统中的商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$emit('deleteProduct', this.id, row)
        })
        .catch(() => {})
    },
    openEditDialog(row) {
      console.log(row)
      this.editProductForm = { ...row }
      this.editProductDialogVisabled = true
    },
    // 编辑商品
    editFormDialogConfim() {
      this.$refs.editProductFormRef.validate((valid) => {
        if (valid) {
          let filterEntity = this.productList.filter((item) => {
            return (
              item.productId == this.editProductForm.productId &&
              item.productModel == this.editProductForm.productModel &&
              item.productSpecs == this.editProductForm.productSpecs
            )
          })

          if (filterEntity.length <= 0) {
            return this.$message.error('当前商品不存在')
          } else {
            let data = { ...this.editProductForm }

            this.$emit('eidtProduct', this.id, data)

            this.editProductDialogVisabled = false
            this.$message.success('编辑商品成功')
          }
        } else {
          return this.$message.error('当前商品参数填写不正确.')
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  width: 200px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.button-new-tag_1 {
  margin-left: 10px;
  height: 32px;
  width: 100px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.el-select {
  width: 100%;
}
</style>
